<template>
  <div class="purchasing-page">
    <div class="purchasing-list">
      <div class="purchasing-item" v-for="(item,index) in list" :key="index">
        <div class="platform df fdr alc" v-if="item.goods_list.length">
          <div class="line"></div>
          <!--1B1自营，2B2自营，3B3自营，4京东，5苏宁-->
          <div v-if="item.source_type">{{getSourceTypes(item.source_type)}}</div>
   
        </div>
        <div class="goods-item df fdr" v-for="(i, index_goods) in item.goods_list" :key="i.id" @click="toGoodsDec(i)">
          <img class="goods-img" :src="i.base_pic" alt="goods_img">
          <div class="goods-info df fdc jc-sb">
            <div>
              <div class="title txt-over" v-if="i.item_name">{{i.item_name}}</div>
              <div class="describe txt-over" v-if="i.item_desc">{{i.item_desc}}</div>
            </div>
            <div>
              <span class="type" v-if="i.property">{{i.property}}</span>
            </div>
            <div class="price df fdr alc" v-if="businessType == 2 && showWay == 1">
              <span v-if="price_status==1">{{(i.price * integralRate).toFixed(2)}}积分</span>
              <span class="old" v-if="mall_price_status==1">{{(i.mall_price * integralRate).toFixed(2)}}积分</span>
            </div>
            <div class="price df fdr alc" v-else>
              <span v-if="price_status==1">￥{{i.price}}</span>
              <span class="old" v-if="mall_price_status==1">￥{{i.mall_price}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getExamineItemList} from "@/services/my";
import {mapState} from "vuex";
import {orderMixin} from "../../../mixins/orderMixin"
export default {
  name: "PurchasingList",
  mixins:[orderMixin],
  data() {
    return {
      pay_order_id: '',//订单id
      list: []
    }
  },
  computed: {
    ...mapState({
      businessType: state => state.businessInfo.type,//1现金商城，2积分商品，3内采商城
      showWay: state => state.config.show_way, // 商品展示方式，1积分，2现金
      price_status: state => state.config.price_status, // 是否展示价格 1展示 0 不展示
      mall_price_status: state => state.config.mall_price_status, //  是否展示价格 1展示 0 不展示
      integralRate: state => state.config.integral_rate // 积分比例
    }),
  },
  methods: {
    getParams() {
      this.pay_order_id = this.$route.query.id ? this.$route.query.id : '';
      if (this.pay_order_id) {
        this.getExamineItemList(this.pay_order_id)
      }

    },
    /**
     * 内采商品列表
     * @param id 订单id
     * @returns {Promise<void>}
     */
    async getExamineItemList(id) {
      try {
        const res = await getExamineItemList(id);
        this.is_request = 1;
        if (res.code === 0) {
          const data = res.data;
          console.log(data);
          let goodList = data;
          let source = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
          source.map(
            item => {
              let goodObj = {};
              goodObj.source_type = item;// 1B1自营，2B2自营，3B3自营，4京东，5苏宁
              goodObj.goods_list = goodList.filter(
                n => n.source_type == item
              );
              this.list.push(goodObj);
            }
          );
          console.log(this.list);
        }
      } catch (e) {

      }
    },
    toGoodsDec(item) {
      this.$router.push({
        name: "goods-detail",
        query: {
          item_id: item.item_id
        }
      });
    },

  },
  mounted() {
    this.getParams()
  }
}
</script>

<style scoped lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.purchasing-page {
  padding: 0.3rem 0.3rem 0;

  .purchasing-list {
    .purchasing-item {
      .platform {
        .line {
          .wh(0.04rem, 0.2rem);
          background: var(--main-color);
          margin-right: 0.2rem;
          border-radius: 0.02rem;
        }

        .sc(0.28rem, @color-dark-grey);
        height: 0.4rem;
        line-height: 0.4rem;
        margin-bottom: 0.2rem;
      }

      .goods-item {
        background: @color-white;
        box-shadow: 0 0.06rem 0.40rem 0 rgba(0, 0, 0, 0.05);
        border-radius: 0.08rem;
        height: 2.2rem;
        margin-bottom: 0.2rem;

        .goods-img {
          .wh(2.2rem, 2.2rem);
          border-bottom-left-radius: 0.08rem;
          border-top-left-radius: 0.08rem;
        }

        .goods-info {
          .wh(4.7rem, 100%);
          padding: 0.24rem 0.1rem 0.2rem 0.2rem;

          .title {
            .sc(0.28rem, @color-dark-grey);
            line-height: 0.4rem;
          }

          .describe {
            .sc(0.24rem, @color-light-grey);
            line-height: 0.34rem;
            margin-bottom: 0.1rem;
          }

          /*类型*/

          .type {
            .sc(0.22rem, var(--main-color));
            height: 0.36rem;
            line-height: 0.36rem;
            padding: 0 0.18rem;
            border-radius: 0.26rem;
            border: 0.02rem solid var(--main-color);
            box-sizing: content-box;
          }

          /*价格*/

          .price {
            .sc(0.32rem, @color-dark-grey);
            font-weight: bold;
            line-height: 0.4rem;
            margin-top: 0.2rem;

            .old {
              .sc(0.24rem, @color-light-grey);
              text-decoration: line-through;
              margin-left: 0.12rem;
            }
          }
        }
      }
    }
  }

}

</style>
