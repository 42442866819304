var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "purchasing-page" }, [
    _c(
      "div",
      { staticClass: "purchasing-list" },
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "purchasing-item" },
          [
            item.goods_list.length
              ? _c("div", { staticClass: "platform df fdr alc" }, [
                  _c("div", { staticClass: "line" }),
                  item.source_type
                    ? _c("div", [
                        _vm._v(_vm._s(_vm.getSourceTypes(item.source_type))),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._l(item.goods_list, function (i, index_goods) {
              return _c(
                "div",
                {
                  key: i.id,
                  staticClass: "goods-item df fdr",
                  on: {
                    click: function ($event) {
                      return _vm.toGoodsDec(i)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "goods-img",
                    attrs: { src: i.base_pic, alt: "goods_img" },
                  }),
                  _c("div", { staticClass: "goods-info df fdc jc-sb" }, [
                    _c("div", [
                      i.item_name
                        ? _c("div", { staticClass: "title txt-over" }, [
                            _vm._v(_vm._s(i.item_name)),
                          ])
                        : _vm._e(),
                      i.item_desc
                        ? _c("div", { staticClass: "describe txt-over" }, [
                            _vm._v(_vm._s(i.item_desc)),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", [
                      i.property
                        ? _c("span", { staticClass: "type" }, [
                            _vm._v(_vm._s(i.property)),
                          ])
                        : _vm._e(),
                    ]),
                    _vm.businessType == 2 && _vm.showWay == 1
                      ? _c("div", { staticClass: "price df fdr alc" }, [
                          _vm.price_status == 1
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    (i.price * _vm.integralRate).toFixed(2)
                                  ) + "积分"
                                ),
                              ])
                            : _vm._e(),
                          _vm.mall_price_status == 1
                            ? _c("span", { staticClass: "old" }, [
                                _vm._v(
                                  _vm._s(
                                    (i.mall_price * _vm.integralRate).toFixed(2)
                                  ) + "积分"
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _c("div", { staticClass: "price df fdr alc" }, [
                          _vm.price_status == 1
                            ? _c("span", [_vm._v("￥" + _vm._s(i.price))])
                            : _vm._e(),
                          _vm.mall_price_status == 1
                            ? _c("span", { staticClass: "old" }, [
                                _vm._v("￥" + _vm._s(i.mall_price)),
                              ])
                            : _vm._e(),
                        ]),
                  ]),
                ]
              )
            }),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }